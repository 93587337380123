<template>
  <b-modal
    id="upgradPlanForFeatureModal"
    ref="upgradPlanForFeatureModal"
    ok-only
    ok-title="View plans"
    @ok="ok"
  >

    <h2>This feature is not available in your plan</h2>
    <span>You can upgrade to Standard or Premium plan to create recurring invoices</span>
    <h5 class="mt-2">
      Check our plan options for more information.
    </h5>
    <ul class="mt-1">
      <li>Unlimited invoices</li>
      <li>Unlimited clients</li>
    </ul>
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  methods: {

    show() {
      this.$refs.upgradPlanForFeatureModal.show()
    },
    close() {
      this.$refs.upgradPlanForFeatureModal.hide()
    },
    ok() {
      this.$destroy()
      this.$router.replace({ name: 'subscription' })
    },

  },

}
</script>
